
<template>
    <div>
        <!--:visible="visible"      赋值 显示变量-->
        <!--@ok="handleOk"          赋值 确认函数-->
        <!--@cancel="handleCancel"  赋值 取消函数-->
        <a-modal title="新建领用记录" okText="确定" cancelText="取消" :visible="visible" @ok="handleOk" @cancel="handleCancel">
            <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="编号">
                    <a-input v-model="form.serial_num" class="w300" disabled=""/>
                </a-form-model-item>
                <a-form-model-item label="名称">
                    <a-input v-model="form.name" class="w300" disabled=""/>
                </a-form-model-item>
                <a-form-model-item label="*领用人">
                    <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" v-model="form.used_id" :options="used_names" placeholder="请选择" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="*领用时间">
                    <a-date-picker v-model="form.used_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="*领用时状况">
                    <a-input v-model="form.borrow_info" class="w300" />
                </a-form-model-item>
                <a-form-model-item label="归还人" v-if="false">
                    <a-input v-model="form.returnee" class="w300" />
                </a-form-model-item>
                <a-form-model-item label="预计归还时间" >
                    <a-date-picker v-model="form.return_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="归还状况" v-if="false">
                    <a-input v-model="form.return_info" class="w300" />
                </a-form-model-item>
                <a-form-model-item label="备注">
                    <a-textarea placeholder="" :rows="4"  v-model="form.remark" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
    /***
     Name: example4
     Code: sa0ChunLuyu
     Time: 2021/10/16 10:19
     Remark: 编辑弹框示例
     */
    export default {
        // 接收 显示变量 和 确定 取消 函数
        props: ['visible', 'ok', 'cancel', 'lingyong_info', 'lingyong_time','close','yiqi_info','lingyong_item'],
        data() {
            return {
                labelCol: {span: 5},
                wrapperCol: {span: 16},
                modal_visible: false,
                form:{
                    serial_num:'',
                    name:'',
                    used_name:[],
                    used_id:[],
                    used_time:'',
                    borrow_info:'',
                    returnee:'',
                    return_time:'',
                    return_info:'',
                    remark:'',

                },
                used_names:[]
            }
        },

        mounted() {
            console.log('this.lingyong_item+++++++++++++++++++++++++++++',this.lingyong_item)
            this.form = {
                serial_num: this.yiqi_info.serial_num,
                name: this.yiqi_info.name,
                used_id:this.lingyong_item.used_id,
                used_name:this.lingyong_item.used_name,
                used_time:this.formatDate(new Date()),
                borrow_info:this.lingyong_item.borrow_info,
                returnee:this.lingyong_item.returnee,
                return_time:this.lingyong_item.return_time,
                return_info:this.lingyong_item.return_info,
                remark:this.lingyong_item.remark,
                type: 1,
            }
            this.Dandaalllist() //项目人所有列表
        },
        methods: {
            formatDate(now) {
                let year = now.getFullYear(),
                    month = now.getMonth() + 1,
                    date = now.getDate(),
                    hour = now.getHours(),
                    minute = now.getMinutes(),
                    second = now.getSeconds();
                return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;
            },
            // 项目负责人所有列表
            Dandaalllist() {
                console.log('response.data.list  Dandaalllist')
                this.$sa0.post({
                    url: this.$api('Dandaalllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            console.log('response.data.list',response.data.list)
                            this.used_names = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 定义 组件 自身的确定函数
            handleOk() {
                let data = {
                    apparatus_id:this.yiqi_info.id,
                    name: this.form.name,
                    serial_num: this.form.serial_num,
                    used_id: this.form.used_id[this.form.used_id.length - 1],
                    used_time:this.form.used_time,
                    borrow_info:this.form.borrow_info,
                    returnee:this.form.returnee,
                    return_time:this.form.return_time,
                    return_info:this.form.return_info,
                    remark:this.form.remark,
                    type: 1,
                }
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Create_apparatusinfo'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.close()
                            console.log(JSON.stringify(data))
                            this.form = {
                                serial_num: this.yiqi_info.serial_num,
                                apparatus_id:this.yiqi_info.id,
                                name: this.form.name,
                                used_time:this.formatDate(new Date()),
                                used_name:[],
                                used_id:[],
                                borrow_info:'',
                                returnee:'',
                                return_time:'',
                                return_info:'',
                                remark:'',
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
                console.log('submit!', this.form);
            },
            onChangetime(value, dateString, e) {
                this.form[`${e}`] = dateString;
            },
            onOk(value) {
                console.log('onOk: ', value);
            },

            // 定义 组件 自身的取消函数
            handleCancel() {
                this.cancel()
            },
        }
    }
</script>

<style scoped>

</style>
