<template>
    <div>
        <a-modal title="添加保管变更记录" okText="确定" cancelText="取消" :visible="visible" @ok="handleOk" @cancel="handleCancel">
            <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="编号">
                    <a-input v-model="form.serial_num" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="名称">
                    <a-input v-model="form.name" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="设备保管人">
                    <a-input v-model="form.change_userid" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="设备位置">
                    <a-input v-model="form.change_address" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="变更保管人">
                    <a-input v-model="form.num" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="变更位置">
                    <a-input v-model="form.change_address" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="备注:">
                    <a-input v-model="form.num" class="w300"/>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
    /***
     Name: example4
     Code: sa0ChunLuyu
     Time: 2021/10/16 10:19
     Remark: 编辑弹框示例
     */
    export default {
        // 接收 显示变量 和 确定 取消 函数
        props: ['visible', 'ok', 'cancel'],
        data() {
            return {
                labelCol: {span: 6},
                wrapperCol: {span: 16},
                modal_visible: false,
                form: {
                    name: '',
                    order: '',
                    region: '',
                    type: ''
                },

            }
        },
        mounted() {
        },
        methods: {
            // 定义 组件 自身的确定函数
            handleOk() {
                this.ok()
            },
            // 定义 组件 自身的取消函数
            handleCancel() {
                this.cancel()
            },
            // 定义 关闭对话框时的 函数
            modalClose3() {
                this.modal_visible3 = false
            },
            // 定义 打开对话框时的 函数
            showModal3() {
                this.modal_visible3 = true
            },
            onChange(e) {
                console.log(`checked = ${e.target.checked}`);
            },
        }
    }
</script>

<style scoped>

</style>
