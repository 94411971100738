<template>
    <div>
        <JPrint ref="doPrint"></JPrint>
        <a-row>
            <a-col :span="24" style="padding-bottom: 15px">
                <a-form-model layout="inline" :model="formInline" @submit.native.prevent>
                    <a-form-model-item label="设备类型">
                        <a-select v-model="formInline.type" placeholder="请选择" class="w300">
                            <a-select-option value="">全部设备</a-select-option>
                            <a-select-option v-for="(item,key ) in types" :key="key" :value="item.id">
                                {{ item.name}}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="设备编号/名称">
                        <a-input v-model="formInline.keywords" class="w200" placeholder="设备编号/名称"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="">
                        <a-button @click="freesearchClick()" type="danger">
                            清空搜索
                        </a-button>
                    </a-form-model-item>
                    <!--            <a-form-model-item label="">-->
                    <!--                <a-button @click="showDrawer({-->
                    <!--            id:0,-->
                    <!--            name:'',-->
                    <!--            serial_num:'',-->
                    <!--            type:'',-->
                    <!--            remark:'',-->
                    <!--            spec:'',-->
                    <!--            model:'',-->
                    <!--            level:'',-->
                    <!--            precision:'',-->
                    <!--            supplier_id:'',-->
                    <!--            factory_num:'',-->
                    <!--            manufacturer:'',-->
                    <!--            trace_type:'',-->
                    <!--            verify_year:'',-->
                    <!--            verify_unit:'',-->
                    <!--            verify_time:null,-->
                    <!--            effect_time:null,-->
                    <!--            nextver_time:null,-->
                    <!--            custos_name:null,-->
                    <!--            storage_address:'',-->
                    <!--            used_name:[],-->
                    <!--            purchase_time:null,-->
                    <!--            stop_time:null,-->
                    <!--            enable_time:null,-->
                    <!--            used_status:'',-->
                    <!--            price:'',-->
                    <!--            is_show_BI:1,-->
                    <!--            arrive_time:null,-->
                    <!--            danda_custos_ids:[],-->
                    <!--            danda_used_ids:[],-->
                    <!--            },false)" type="primary">新建仪器-->
                    <!--                </a-button>-->
                    <!--            </a-form-model-item>-->

                    <!--            <a-form-model-item label="" style="float: right">-->
                    <!--                <a-button type="primary" @click="Apparatus_export()">-->
                    <!--                    导出搜索结果-->
                    <!--                </a-button>-->
                    <!--            </a-form-model-item>-->
                </a-form-model>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="24" style="margin-bottom: -10px">
                <a-space style="" class="btner">
                    <div class="btnerlist " @click="showDraweradd({
            id:0,
            name:'',
            serial_num:'',
            type:'',
            remark:'',
            spec:'',
            model:'',
            level:'',
            precision:'',
            supplier_id:'',
            factory_num:'',
            manufacturer:'',
            trace_type:'',
            verify_year:'',
            verify_unit:'',
            verify_time:null,
            effect_time:null,
            nextver_time:null,
            custos_name:null,
            storage_address:'',
            used_name:[],
            purchase_time:null,
            stop_time:null,
            enable_time:null,
            used_status:'',
            price:'',
            is_show_BI:1,
            arrive_time:null,
            danda_custos_ids:[],
            danda_used_ids:[],
            },false)">
                        <i class="iconfont icon-jianyan2" style=" vertical-align: -1px"></i>新建仪器
                    </div>
                    <div class="btnerlist" @click="showDrawer(true)">
                        <i class="iconfont icon-baogao" style=" vertical-align: -1px"></i>设备详情
                    </div>
                    <div class="btnerlist" @click="Apparatus_export()">
                        <i class="iconfont icon-weibiaoti545" style="font-size: 18px; vertical-align: -1px"></i>导出搜索结果
                    </div>

                </a-space>
            </a-col>
            <a-col :span="24">
                <table class="layui-table" lay-size="sm">
                    <thead>
                    <tr>
                        <th></th>
                        <th>编号</th>
                        <th>名称</th>
                        <th>型号</th>
                        <th>规格</th>
                        <th>有效期止</th>
                        <th>设备类型</th>
                        <th>设备状态</th>
                        <th>保管人</th>
                        <th>使用人</th>
                        <th>存放位置</th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="( item,key) in tableData" :key="key" @click="clickrow(key)" :class="item.check ? 'active':''">
                        <td>
                            <a-checkbox v-model="item.check"></a-checkbox>
                        </td>
                        <td>{{item.serial_num}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.model}}</td>
                        <td>{{item.spec}}</td>
                        <td>{{item.effect_time}}</td>
                        <td>{{item.type_name}}</td>
                        <td>
                            <a-badge status="success" v-if="item.used_status==1"/>
                            <a-badge status="processing" v-if="item.used_status==2"/>
                            <a-badge status="warning" v-if="item.used_status==4"/>
                            <a-badge status="error" v-if="item.used_status==5"/>
                            <span :class="infoStatus(item.used_status)"> {{wordType(item.used_status)}} </span>
                        </td>
                        <td>{{item.custos_name}}</td>
                        <td>{{item.used_name}}</td>
                        <td>{{item.storage_address_name }}</td>

                    </tr>
                    </tbody>
                </table>
            </a-col>
            <!--分页-->
            <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
        </a-row>
        <div v-if="edit_info">
            <Createyiqi @PrintTAG="PrintTAG" :templateList="templateList" :reloadList="reloadList" :edit_time="edit_time" :do_type="do_type" :edit_info="edit_info" :close="onCloseDrawer" :visible="drawer_visible"></Createyiqi>
        </div>
    </div>
</template>
<script>
    // 创建组件
    import Createyiqi from '../../../components/drawer/shebeishow/index.vue';
    import JPrint from '@/components/print/JPrint'

    export default {
        components: {Createyiqi, JPrint},
        props: ['visible', 'close'],
        data() {
            return {
                page: 1,
                count: 0,
                pagesize: 0,
                do_type: false,
                edit_info: false,
                edit_time: 0,
                tableData: [],
                drawer_visible: false,
                formInline: {
                    type: '',
                    keywords: '',
                    custos_id: '',
                    danda_custos_ids: []
                },
                types: [],
                custos_ids: [],
                page_show: false,
                templateList: []////标签模板
            };
        },
        watch: {
            formInline: {
                handler() {
                    if (this.page_show) this.searchClick()
                },
                deep: true
            },
            $route: {
                handler() {
                    this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
                    this.type = this.$route.query.type || '';
                    this.keywords = this.$route.query.keywords || '';
                    // this.custos_id = this.$route.query.custos_id || '';
                    this.Get_apparatus()

                },
                deep: true
            }
        },
        mounted() {
            this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
            this.formInline.type = this.$route.query.type || '';
            this.formInline.keywords = this.$route.query.keywords || '';
            this.Get_apparatus(0)  //仪器列表
            this.Sampletypelist()  //仪器类别
            // this.Dandaalllist()  //项目负责人所有列表
            setTimeout(() => {
                this.page_show = true
            }, 1)
            this.taglist(2)
        },
        methods: {
            PrintTAG(src_list) {
                this.$refs.doPrint.printIt(src_list);
            },
            taglist(id) {
                this.$sa0.post({
                    url: this.$api('Get_tag'),
                    data: {
                        tag_type: id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.templateList = response.data.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            //仪器信息导出
            Apparatus_export() {
                console.log(JSON.stringify({
                    page: this.page,
                    type: this.formInline.type,
                    keywords: this.formInline.keywords,
                    // custos_id: this.formInline.custos_id,
                }))
                this.$sa0.down({
                    url: this.$api('Apparatus_export'),
                    file_name: 'down1.xlsx',
                    data: {
                        page: this.page,
                        type: this.formInline.type,
                        keywords: this.formInline.keywords,
                        // custos_id: this.formInline.custos_id,
                    },
                },)
            },

            infoStatus(status) {
                let status_arr = (status.toString()).split(',')
                let red_arr = [5];
                let green_arr = [1];
                let blue_arr = [2];
                let warning_arr = [4];
                let res_c = '';
                status_arr.map((item) => {
                    if (red_arr.indexOf(Number(item)) !== -1) {
                        res_c = 'red';
                    }
                    // if (green_arr.indexOf(Number(item)) !== -1) {
                    //     res_c = 'green';
                    // }
                    // if (blue_arr.indexOf(Number(item)) !== -1) {
                    //     res_c = 'blue';
                    // }
                    // if (warning_arr.indexOf(Number(item)) !== -1) {
                    //     res_c = 'warning';
                    // }
                })
                return res_c;
            },
            freesearchClick() {
                this.formInline = {
                    type: '',
                    keywords: '',
                }
                this.page = 1
                // this.searchClick()
            },
            reloadList(id) {
                this.Get_apparatus(id)
            },
            // groupIdChange(e) {
            //     this.Get_apparatus()
            // },

            clickrow(key) {
                this.$set(this.tableData[key], 'check', !this.tableData[key].check)
                this.itemActiveChange()
            },
            itemActiveChange() {
                let do_ = true
                this.tableData.map((item, key) => {
                    if (!item.check) do_ = false
                })
                this.all_active = do_
            },
            allChange(e) {
                this.tableData.map((item, key) => {
                    this.$set(this.tableData[key], 'active', this.all_active)
                })
            },
            //获取仪器列表
            Get_apparatus(id) {
                this.$sa0.post({
                    url: this.$api('Get_apparatus'),
                    data: {
                        page: this.page,
                        type: this.type,
                        keywords: this.keywords,

                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            // this.tableData = response.data.return.list;
                            this.tableData = response.data.return.list.map((item) => {
                                item.danda_custos_ids = item.danda_custos_ids ? item.danda_custos_ids : ''
                                let ids = (item.danda_custos_ids.toString()).split(',')
                                let i = ids.map((ii) => {
                                    return Number(ii)
                                })
                                item.danda_custos_ids = i;
                                item.danda_used_ids = item.danda_used_ids ? item.danda_used_ids : ''
                                let danda_used_ids = (item.danda_used_ids.toString()).split(',')
                                let i1 = danda_used_ids.map((ii) => {
                                    return Number(ii)
                                })
                                item.danda_used_ids = i1;
                                item.check = false
                                return item;
                            });

                            // 子传父  判断
                            if (id !== 0) {
                                this.tableData.map((item) => {
                                    if (Number(item.id) === Number(id)) {
                                        this.showDrawer(item, true)
                                    }
                                })
                            }
                            this.count = Number(response.data.return.count);//分页
                            this.pagesize = Number(response.data.return.pagesize);//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 获取仪器分类
            Sampletypelist() {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: 8
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.types = response.data.list
                            console.log('types', this.types)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 项目负责人所有列表
            // Dandaalllist() {
            //     this.$sa0.post({
            //         url: this.$api('Dandaalllist'),
            //         data: {},
            //     }).then((response) => {
            //         this.$sa0.response({
            //             response: response.data,
            //             then: (response) => {
            //                 this.custos_ids = response.data.list;
            //             },
            //             error: (response) => {
            //                 layer.msg(response.message)
            //             },
            //         })
            //     })
            // },

            // 搜索
            searchClick() {
                this.page = 1;
                this.$router.push({
                    query: {
                        type: this.formInline.type,
                        keywords: this.formInline.keywords,

                    }
                })
            },

            // 类型转换
            wordType(status) {
                let type_arr = ['空闲', '使用中', '送检中', '借出', '维修中', '领用待确认', '归还待确认']
                if (type_arr.length >= Number(status)) {
                    return type_arr[Number(status) - 1]
                } else {
                    return status
                }
            },

            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        page: page
                    }
                })
            },

            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.drawer_visible = false;
                this.Get_apparatus()  //仪器列表
            },
            // 定义 打开抽屉时的 函数
            showDrawer(type) {
                let id = 0
                let count = 0
                let info = ''
                this.tableData.map((item, key) => {
                    if (item.check) {
                        id = item.id
                        count++
                        info = item
                    }
                })
                if (!id) return layer.msg('请选择仪器')
                if (count !== 1) return layer.msg('只能选择一个仪器')
                this.edit_info = info
                this.edit_time = new Date() / 1;
                this.drawer_visible = true;
                this.do_type = type
            },


            showDraweradd(item, type) {
                this.edit_info = item
                this.edit_time = new Date() / 1;
                this.drawer_visible = true;
                this.do_type = type
            },
        },
    };
</script>

<style scoped>
    /*******************w按钮区开始w*********************
        ***********************************************/
    .btner{
        width: 100%;
        background: #f3f3f3;
        padding: 5px 10px;
        font-size: 12px;
        color: #333;
        border: 1px solid #eeeeee;
        }

    .btnerlist{
        cursor: pointer;
        border-right: 1px solid #e9e8e8;
        height: 26px;
        line-height: 24px;
        padding: 0 12px
        }

    .btner:last-child{ border: none}

    .isthis, .btnerlist:hover{
        background: #1592fe;
        color: #ffffff;
        border-radius: 4px;
        height: 26px;
        line-height: 24px;
        }

    .btnerlist i{
        color: #999999;
        font-size: 16px;
        margin-right: 3px;
        }

    .isthis i, .btnerlist:hover i{ color: #ffffff !important}

    .isDel, .isDel:hover{ background: #ff4d4f;border-radius: 4px; color: #ffffff !important}

    .isDel i, .isDel:hover, .isDel:hover i{ color: #ffffff !important}

    /*******************w按钮区结束w*********************
    ***********************************************/


    .w200{ width: 200px}

    .ml5{ margin-left: 5px}

    .green{ color: #52c41a}

    .red{ color: #ff0000}

    .gray{ color: #999999}

    .blue{ color: #1890ff}

    .warning{ color: #faad14}

    .layui-table, .layui-table td, .layui-table th{
        border: 1px solid #eeeeee;
        }

    .active{ background: #ebf6ff}


</style>
