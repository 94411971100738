<template>
    <div>
        <!--:visible="visible"      赋值 显示变量-->
        <!--@ok="handleOk"          赋值 确认函数-->
        <!--@cancel="handleCancel"  赋值 取消函数-->
        <a-modal title="新建检定校准记录" okText="确定" cancelText="取消" :visible="visible" @ok="handleOk" @cancel="handleCancel">
            <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="名称">
                    <a-input v-model="yiqi_info.name" class="w300" disabled=""/>
                </a-form-model-item>
                <a-form-model-item label="*检定日期">
                    <a-date-picker v-model="form.repair_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="*有效期">
                    <a-date-picker v-model="form.valid_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="*下次检定日期">
                    <a-date-picker v-model="form.maintain_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="检定结果">
                    <a-input v-model="form.result_info" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="备注">
                    <a-textarea placeholder="" :rows="4" v-model="form.remark"/>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
    /***
     Name: example4
     Code: sa0ChunLuyu
     Time: 2021/10/16 10:19
     Remark: 编辑弹框示例
     */
    export default {
        // 接收 显示变量 和 确定 取消 函数
        props: ['visible', 'ok', 'cancel', 'jianding_info', 'jianding_time', 'close', 'yiqi_info', 'jianding_item'],
        data() {
            return {
                labelCol: {span: 6},
                wrapperCol: {span: 16},
                modal_visible: false,
                form: {
                    name: '',
                    repair_time: '',
                    valid_time: '',
                    maintain_time: '',
                    result_info: '',
                    remark: '',
                    type: 5,
                },
            }
        },
        mounted() {
            console.log('this.yiqi_info', this.yiqi_info.name)
            this.form = {
                name: this.jianding_item.name,
                repair_time: this.formatDate(new Date()),
                valid_time: this.jianding_item.valid_time,
                maintain_time: this.jianding_item.maintain_time,
                result_info: this.jianding_item.result_info,
                remark: this.jianding_item.remark,
                type: 5,
            }
        },
        methods: {
            formatDate(now) {
                let year = now.getFullYear(),
                    month = now.getMonth() + 1,
                    date = now.getDate(),
                    hour = now.getHours(),
                    minute = now.getMinutes(),
                    second = now.getSeconds();
                return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;
            },
            // 定义 组件 自身的确定函数
            handleOk() {
                let data = {
                    apparatus_id: this.yiqi_info.id,
                    name: this.yiqi_info.name,
                    repair_time: this.form.repair_time,
                    valid_time: this.form.valid_time,
                    maintain_time: this.form.maintain_time,
                    result_info: this.form.result_info,
                    remark: this.form.remark,
                    type: 5,
                }
                console.log(JSON.stringify(data))

                this.$sa0.post({
                    url: this.$api('Create_apparatusinfo'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.close()
                            console.log(JSON.stringify(data))
                            this.form = {
                                name: '',
                                repair_time: this.formatDate(new Date()),
                                valid_time: '',
                                maintain_time: '',
                                result_info: '',
                                remark: '',
                                type: 5,
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
                console.log('submit!', this.form);
            },
            // 定义 组件 自身的取消函数
            handleCancel() {
                this.cancel()
            },
            // 定义 关闭对话框时的 函数
            modalClose() {
                this.modal_visible1 = false
            },
            // 定义 打开对话框时的 函数
            showModal() {
                this.modal_visible1 = true
            },
            onChange(e) {
                console.log(`checked = ${e.target.checked}`);
            },
            onChangetime(value, dateString, e) {
                this.form[`${e}`] = dateString;
            },
            onOk(value) {
                console.log('onOk: ', value);
            },
        }
    }
</script>

<style scoped>

</style>
