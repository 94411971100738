<template>
    <div>
        <!--:visible="visible"      赋值 显示变量-->
        <!--@ok="handleOk"          赋值 确认函数-->
        <!--@cancel="handleCancel"  赋值 取消函数-->
        <a-modal title="新建外借记录" okText="确定" cancelText="取消" :visible="visible" @ok="handleOk" @cancel="handleCancel">
            <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="编号">
                    <a-input v-model="yiqi_info.serial_num" class="w300" disabled=""/>
                </a-form-model-item>
                <a-form-model-item label="名称">
                    <a-input v-model="yiqi_info.name" class="w300" disabled=""/>
                </a-form-model-item>
                <a-form-model-item label="*借出单位">
                    <a-input v-model="form.borrow_unit" class="w300"/>
                </a-form-model-item>
<!--                <a-form-model-item label="借出地址">-->
<!--                    <a-input v-model="form.unit_address" class="w300"/>-->
<!--                </a-form-model-item>-->
                <a-form-model-item label="*借用人">
                    <a-input v-model="form.borrower" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="*手机号">
                    <a-input v-model="form.phone" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="*借出日期">
                    <a-date-picker v-model="form.borrow_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="w300"/>

                </a-form-model-item>
                <a-form-model-item label="预计归还日期">
                    <a-date-picker v-model="form.return_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="借出时状态">
                    <a-input v-model="form.borrow_info" class="w300"/>
                </a-form-model-item>
<!--                <a-form-model-item label="检查结果">-->
<!--                    <a-input v-model="form.result_info" class="w300"/>-->
<!--                </a-form-model-item>-->
                <a-form-model-item label="备注">
                    <a-textarea placeholder="" :rows="4"  v-model="form.remark" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
    /***
     Name: example4
     Code: sa0ChunLuyu
     Time: 2021/10/16 10:19
     Remark: 编辑弹框示例
     */
    export default {
        // 接收 显示变量 和 确定 取消 函数
        props: ['visible', 'ok', 'cancel','waijie_info', 'waijie_time','close','yiqi_info','waijie_item'],

        data() {
            return {
                labelCol: {span: 5},
                wrapperCol: {span: 16},
                modal_visible: false,
                form: {
                    serial_num:'',
                    name:'',
                    borrow_unit:'',
                    unit_address:'',
                    used_name:'',
                    phone:'',
                    borrow_time:'',
                    return_time:'',
                    borrow_info:'',
                    result_info:'',
                    remark:'',
                    type:2,
                },
            }
        },

        mounted() {
            console.log('this.yiqi_info',this.yiqi_info.name)
            this.form = {
                serial_num: this.waijie_item.serial_num,
                name: this.waijie_item.name,
                borrow_unit: this.waijie_item.borrow_unit,
                unit_address: this.waijie_item.unit_address,
                borrower: this.waijie_item.borrower,
                phone: this.waijie_item.phone,
                borrow_time: this.formatDate(new Date()),
                return_time: this.waijie_item.return_time,
                borrow_info: this.waijie_item.borrow_info,
                result_info: this.waijie_item.result_info,
                remark: this.waijie_item.remark,
                type: 2,
            }


        },
        methods: {



            formatDate(now) {
                let year = now.getFullYear(),
                    month = now.getMonth() + 1,
                    date = now.getDate(),
                    hour = now.getHours(),
                    minute = now.getMinutes(),
                    second = now.getSeconds();
                return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;
            },
            // 定义 组件 自身的确定函数
            // handleOk() {
            //     this.ok()
            // },
            handleOk() {
                let data = {
                    apparatus_id:this.yiqi_info.id,
                    name: this.yiqi_info.name,
                    serial_num: this.yiqi_info.serial_num,
                    borrow_unit: this.form.borrow_unit,
                    unit_address: this.form.unit_address,
                    borrower: this.form.borrower,
                    phone: this.form.phone,
                    borrow_time: this.form.borrow_time,
                    return_time: this.form.return_time,
                    borrow_info: this.form.borrow_info,
                    result_info: this.form.result_info,
                    remark: this.form.remark,
                    type: 2,
                }
                console.log(JSON.stringify(data))

                this.$sa0.post({
                    url: this.$api('Create_apparatusinfo'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.close()
                            // console.log(JSON.stringify(data))
                            this.form = {
                                serial_num:'',
                                name:'',
                                borrow_time: this.formatDate(new Date()),
                                borrow_unit:'',
                                unit_address:'',
                                borrower:'',
                                phone:'',
                                return_time:'',
                                borrow_info:'',
                                result_info:'',
                                remark:'',
                                type:2,
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
                console.log('submit!', this.form);
            },
            // 定义 组件 自身的取消函数
            handleCancel() {
                this.cancel()
            },
            // 定义 关闭对话框时的 函数
            modalClose() {
                this.modal_visible = false
            },
            // 定义 打开对话框时的 函数
            showModal() {
                this.modal_visible = true
            },
            onChangetime(value, dateString, e) {
                this.form[`${e}`] = dateString;
            },

            onOk(value) {
                console.log('onOk: ', value);
            },

            onChange(e) {
                console.log(`checked = ${e.target.checked}`);
            },
        }
    }
</script>

<style scoped>

</style>
