<template>
    <div>

        <a-drawer :title="`${info.id===0?'新建仪器':'设备详情'}`" width="95%" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div class="print_img_wrapper">
                <div style="border: solid 1px #efefef; width: 260px; height: 260px;display: none" id="tiaoma">
                    <img :src="default_tag.qrcode_url">
                </div>
            </div>

            <a-row type="flex" justify="center" align="top">
                <a-col :span="3" class="list-left">
                    <a-tabs default-active-key="1" v-model="tab_active" :tab-position="tabPosition" type="card">
                        <a-tab-pane key="1" tab="基本信息"></a-tab-pane>
                        <!--                        <a-tab-pane key="2" tab="附件证书"></a-tab-pane>-->
                        <a-tab-pane key="3" tab="领用记录"></a-tab-pane>
                        <a-tab-pane key="4" tab="外借记录"></a-tab-pane>
                        <a-tab-pane key="5" tab="检定校准"></a-tab-pane>
                        <a-tab-pane key="6" tab="维护保养"></a-tab-pane>
                        <a-tab-pane key="7" tab="保管变更"></a-tab-pane>
                        <a-tab-pane key="8" tab="仪器工作参数"></a-tab-pane>
                        <a-tab-pane key="9" tab="仪器输出数值"></a-tab-pane>
                        <!--                        <a-tab-pane key="10" tab="仪器模板"></a-tab-pane>-->
                    </a-tabs>
                </a-col>
                <a-col :span="21" class="list-right">
                    <div style="margin-top: 20px" v-if="tab_active === '1'">
                        <a-form-model :model="info" layout="inline" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-row>
                                <a-col :span="7">
                                    <a-form-model-item label="*编号" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0}" style="width: 100%">
                                        <!--                                        <a-input ref="userNameInput" v-model="info.serial_num" placeholder="">-->
                                        <!--                                            <a-tooltip slot="suffix" title="使用默认规则" @click="nummoren()">-->
                                        <!--                                                <a-icon type="unordered-list" style="color: rgba(0,0,0,.45)"/>-->
                                        <!--                                            </a-tooltip>-->
                                        <!--                                        </a-input>-->


                                        <a-space style="position: relative">
                                            <a-input placeholder="人员编号" class="w300" v-model="info.serial_num" @keyup.enter="nummoren"></a-input>
                                            <a-dropdown style="position: absolute; right: 15px; top: 15px">
                                                <a-icon type="unordered-list" @click="nummoren()" style="cursor:pointer; vertical-align: middle"/>
                                                <a-menu slot="overlay">
                                                    <a-menu-item v-for="(ri,rk) in yangpinguize_list" :key="rk" @click="getyangpinbianhao(ri.id)">
                                                        {{ ri.name }} {{ ri.serialnumber }}
                                                    </a-menu-item>
                                                </a-menu>
                                            </a-dropdown>
                                        </a-space>


                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="10">
                                    <a-form-model-item label="" :label-col="{ span: 0, offset: 0 }" :wrapper-col="{ span:8,offset: 1}">
                                        <a-space>
                                            <!--                                            <a-button type="primary" @click="showModal01()">选择编号规则</a-button>-->
                                            <!--@click="showDrawerP(info,0)"-->
                                            <a-button class="defaultTag" @click="doPrint">使用默认标签打印</a-button>
                                            <div class="print-tag">
                                                <!--                        @click="showDrawerP(info,1)"-->
                                                <a-button type="primary">选择其它标签打印</a-button>
                                                <div class="print-show">
                                                    <div v-for="(item,index) in templateList" :key="item.id"> {{
                                                        item.name }}
                                                        <img :src="getImg(item)" style="width:150px">
                                                        <a-button type="primary" @click="PrintTAG(index)">打 印</a-button>
                                                    </div>
                                                </div>
                                            </div>

                                        </a-space>
                                    </a-form-model-item>
                                </a-col>
                            </a-row>

                            <a-form-model-item label="*名称" style="width: 500px">
                                <a-input v-model="info.name" class="w300"/>
                            </a-form-model-item>
                            <a-form-model-item label="*设备类别" style="width: 500px">
                                <a-select v-model="info.type" placeholder="请选择" class="w300">
                                    <a-select-option v-for="(item,key ) in types" :key="key" :value="item.id">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                            <br>
                            <a-form-model-item label="*型号" style="width: 500px">
                                <a-input v-model="info.model" class="w300"/>
                            </a-form-model-item>
                            <a-form-model-item label="规格" style="width: 500px">
                                <a-input v-model="info.spec" class="w300"/>
                            </a-form-model-item>
                            <br>
                            <a-form-model-item label="等级" style="width: 500px">
                                <a-input v-model="info.level" class="w300"/>
                            </a-form-model-item>

                            <a-form-model-item label="精度" style="width: 500px">
                                <a-input v-model="info.precision" class="w300"/>
                            </a-form-model-item>
                            <br>
                            <!--                            <a-form-model-item label="供应商" style="width: 500px">-->
                            <!--                                <a-select v-model="info.supplier_id" placeholder="请选择" class="w300">-->
                            <!--                                    <a-select-option v-for="(item,key ) in Manufacturerlist" :key="key" :value="item.id">-->
                            <!--                                        {{ item.full_name}}-->
                            <!--                                    </a-select-option>-->
                            <!--                                </a-select>-->


                            <!--                            </a-form-model-item>-->
                            <a-form-model-item label="出厂编号" style="width: 500px">
                                <a-input v-model="info.factory_num" class="w300"/>
                            </a-form-model-item>

                            <a-form-model-item label="生产厂家" style="width: 500px">
                                <a-select v-model="info.supplier_id" placeholder="请选择" class="w300">
                                    <a-select-option v-for="(item,key ) in manufacturer" :key="key" :value="item.id">
                                        {{ item.full_name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                            <br>
                            <a-form-model-item label="溯源方式" style="width: 500px">
                                <a-select class="w300" default-value="请选择" v-model="info.trace_type">
                                    <a-select-option :value="1">
                                        检定
                                    </a-select-option>
                                    <a-select-option :value="2">
                                        校准
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>

                            <a-form-model-item label="检定周期(年)" style="width: 500px">
                                <a-input v-model="info.verify_year" class="w300"/>
                            </a-form-model-item>
                            <br>
                            <a-form-model-item label="检定单位" style="width: 500px">
                                <a-input v-model="info.verify_unit" class="w300"/>
                            </a-form-model-item>

                            <a-form-model-item label="检定/校准日期" style="width: 500px">
                                <a-date-picker v-model="info.verify_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="w300" disabled v-if="info.id !==0"/>
                                <a-date-picker v-model="info.verify_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="w300" v-if="info.id===0"/>
                            </a-form-model-item>
                            <br>
                            <a-form-model-item label="有效期止" style="width: 500px">
                                <a-date-picker v-model="info.effect_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="w300" disabled v-if="info.id !==0"/>
                                <a-date-picker v-model="info.effect_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="w300" v-if="info.id===0"/>
                            </a-form-model-item>

                            <a-form-model-item label="下次检定日期" style="width: 500px">
                                <a-date-picker v-model="info.nextver_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="w300" disabled v-if="info.id !==0"/>
                                <a-date-picker v-model="info.nextver_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="w300" v-if="info.id===0"/>
                            </a-form-model-item>
                            <br>
                            <a-form-model-item label="保管人" style="width: 500px">
                                <!-- {{ info.danda_custos_ids }}-->
                                <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" v-model="info.danda_custos_ids" :options="custos_ids" placeholder="请选择" class="w300"/>
                            </a-form-model-item>

                            <a-form-model-item label="存放位置" style="width: 500px">
                                <!--                                {{ storage_address }} {{ info.storage_address }}-->
                                <a-select v-model="info.storage_address" placeholder="请选择" class="w300">
                                    <a-select-option v-for="(i,k ) in storage_addresser" :key="k" :value="i.id">{{
                                        i.name
                                        }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                            <br>
                            <a-form-model-item label="使用人" style="width: 500px">
                                <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" v-model="info.danda_used_ids" :options="used_ids" placeholder="请选择" class="w300"/>
                            </a-form-model-item>

                            <a-form-model-item label="购入日期" style="width: 500px">
                                <!--                                <a-date-picker show-time placeholder="请选择" format="YYYY-MM-DD" :value="info.purchase_time" @change="(e,dateString)=>{onChangetime(e,dateString,'purchase_time')}" @ok="onOk" class="w300"/>-->

                                <a-date-picker v-model="info.purchase_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="w300"/>

                            </a-form-model-item>
                            <br>
                            <!--                            <a-form-model-item label="停用时间" style="width: 500px">-->
                            <!--                                <a-date-picker show-time placeholder="请选择" format="YYYY-MM-DD" :value="info.stop_time" @change="(e,dateString)=>{onChangetime(e,dateString,'stop_time')}" @ok="onOk" class="w300"/>-->
                            <!--                            </a-form-model-item>-->
                            <!--                            <br>-->
                            <a-form-model-item label="启用日期" style="width: 500px">
                                <!--                                <a-date-picker show-time placeholder="请选择" format="YYYY-MM-DD" :value="info.enable_time" @change="(e,dateString)=>{onChangetime(e,dateString,'enable_time')}" @ok="onOk" class="w300"/>-->

                                <a-date-picker v-model="info.enable_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="w300"/>

                            </a-form-model-item>
                            <a-form-model-item label="当前状态" style="width: 500px">
                                <a-select default-value="请选择" class="w300" v-model="info.used_status">
                                    <a-select-option :value="1">
                                        空闲
                                    </a-select-option>
                                    <a-select-option :value="2">
                                        使用
                                    </a-select-option>
                                    <!--                                    <a-select-option :value="3">-->
                                    <!--                                        送检中-->
                                    <!--                                    </a-select-option>-->
                                    <a-select-option :value="4">
                                        借出
                                    </a-select-option>
                                    <a-select-option :value="5">
                                        维修中
                                    </a-select-option>
                                    <!--                                    <a-select-option :value="6">-->
                                    <!--                                        领用待确认-->
                                    <!--                                    </a-select-option>-->
                                    <!--                                    <a-select-option :value="7">-->
                                    <!--                                        归还待确认-->
                                    <!--                                    </a-select-option>-->
                                </a-select>
                            </a-form-model-item>
                            <br>
                            <a-form-model-item label="价格(元)" style="width: 500px">
                                <a-input v-model="info.price" class="w300"/>
                            </a-form-model-item>
                            <a-form-model-item label="到达日期" style="width: 500px">
                                <!--                                <a-date-picker show-time placeholder="请选择" format="YYYY-MM-DD" :value="info.arrive_time" @change="(e,dateString)=>{onChangetime(e,dateString,'arrive_time')}" @ok="onOk" class="w300"/>-->

                                <a-date-picker v-model="info.arrive_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="w300"/>

                            </a-form-model-item>
                            <br>
                            <a-form-model-item label="是否在BI大屏中展示" style="width: 500px">
                                <a-radio-group name="radioGroup" :default-value="1" v-model="info.is_show_BI">
                                    <a-radio :value="1">是</a-radio>
                                    <a-radio :value="2">否</a-radio>
                                </a-radio-group>
                            </a-form-model-item>
                        </a-form-model>
                        <a-form-model-item label="备注" style="margin-left: 100px">
                            <a-textarea placeholder="请填写备注" :rows="4" v-model="info.remark" style="width: 880px"/>
                        </a-form-model-item>
                        <a-form-model-item :wrapper-col="{ span: 10, offset: 1 }">
                            <a-button type="primary" @click="doClick()" style="width: 180px; height: 40px; margin-top: 20px; margin-left: 40px" v-if="!do_type">
                                确定
                            </a-button>
                            <a-button type="primary" @click="doClickadd()" style="width: 180px; height: 40px; margin-top: 20px; margin-left: 40px" v-if="do_type">
                                确定
                            </a-button>
                        </a-form-model-item>
                    </div>
                    <!--                    <div style="margin-top: 20px" v-if="tab_active === '2'">-->
                    <!--                        附件证书-->
                    <!--                    </div>-->
                    <!--                    tab_active 4 外借  type 2-->
                    <!--                    tab_active 3 领用  type 1-->
                    <div style="margin-top: 20px" v-if="tab_active === '3' ">
                        <div v-if="do_type && info.used_status===1">
                            <a-button @click="showModal00({
                    id:0,
                    serial_num:'',
                    name:'',
                    used_name:[],
                    used_id:[],
                    used_time:'',
                    borrow_info:'',
                    returnee:[],
                    return_time:'',
                    return_info:'',
                    remark:'',
            })" type="primary">新建领用记录
                            </a-button>
                        </div>
                        <table class="layui-table" lay-size="sm">
                            <thead>
                            <tr>
                                <th>领用人</th>
                                <th>领用时间</th>
                                <th>领用时状况</th>
                                <th>归还人</th>
                                <th>归还时间</th>
                                <th>归还时状况</th>
                                <th>备注</th>
                                <th>操作</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="( item,key) in lingyongtableData" :key="key">
                                <td>{{ item.used_name }}</td>
                                <td>{{ item.used_time }}</td>
                                <td>{{ item.borrow_info }}</td>
                                <td>{{ item.returnee }}</td>
                                <td>{{ item.return_data }}</td>
                                <td>{{ item.return_info }}</td>
                                <td>{{ item.remark }}</td>
                                <td>
                                    <a-button type="primary" class="ml20" @click="showModal0(item)" v-if="Number(item.return_status)!==1 && Number(item.type)===1">
                                        归还
                                    </a-button>
                                    <a-button type="link" class="ml20" v-if="Number(item.return_status)==1">
                                        已归还
                                    </a-button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <!--分页-->
                        <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
                    </div>
                    <div style="margin-top: 20px" v-if="tab_active === '4'">
                        <div style="margin-top: 20px">
                            <div v-if="do_type && info.used_status===1">
                                <a-button @click="showModal({
                    id:0,
                    serial_num:'',
                    name:'',
                    borrow_unit:'',
                    unit_address:'',
                    used_name:'',
                    borrower:'',
                    phone:'',
                    borrow_time:'',
                    return_time:'',
                    borrow_info:'',
                    result_info:'',
                    remark:'',
            })" type="primary">新建外借记录
                                </a-button>
                            </div>
                            <div>
                                <table class="layui-table" lay-size="sm">
                                    <thead>
                                    <tr>
                                        <th>编号</th>
                                        <th>名称</th>
                                        <th>借出单位</th>
                                        <th>手机号</th>
                                        <th>借用人</th>
                                        <th>借用时状态</th>
                                        <th>借出日期</th>
                                        <th>归还日期</th>
                                        <th>备注</th>
                                        <th>归还状态</th>
                                        <th>操作</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="( item,key) in waijietableData" :key="key">
                                        <td>{{ item.serial_num }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.borrow_unit }}</td>
                                        <td>{{ item.phone }}</td>

                                        <td>{{ item.borrower }}</td>
                                        <td>{{ item.borrow_info }}</td>

                                        <td>{{ item.borrow_time }}</td>
                                        <td>{{ item.return_time }}</td>
                                        <td>
                                            <a-tooltip>
                                                <template slot="title">
                                                    {{ item.remark }}
                                                </template>
                                                <a-button type="link" class="ml20">
                                                    查看详情
                                                </a-button>
                                            </a-tooltip>
                                        </td>
                                        <td>{{ item.return_info }}</td>

                                        <td>
                                            <a-button type="primary" @click="showModal0(item)" v-if="Number(item.return_status)!==1 && Number(item.type)===2">
                                                归还
                                            </a-button>
                                            <a-button type="link" class="ml20" v-if="Number(item.return_status)==1">
                                                已归还
                                            </a-button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                                <a-pagination :current="pagewaijie" :pageSize="pagesizewaijie" @change="onChangewaijie" :total="countwaijie" v-if="countwaijie>pagesizewaijie"/>

                            </div>
                        </div>

                    </div>
                    <div style="margin-top: 20px" v-if="tab_active === '5'">
                        <div style="margin-top: 20px">
                            <div v-if="do_type">
                                <a-button @click="showModal1({
                    id:0,
                    name:'',
                    repair_time:'',
                    valid_time:'',
                    maintain_time:'',
                    result_info:'',
                    remark:'',
            })" type="primary">新建检定校准记录
                                </a-button>
                            </div>
                            <div>
                                <table class="layui-table" lay-size="sm">
                                    <thead>
                                    <tr>
                                        <th>名称</th>
                                        <th>检定/校准日期</th>
                                        <th>有效期</th>
                                        <th>下次检定日期</th>
                                        <th>结果</th>
                                        <th>备注</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="( item,key) in jiaozhuntableData" :key="key">
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.repair_time }}</td>
                                        <td>{{ item.valid_time }}</td>
                                        <td>{{ item.maintain_time }}</td>
                                        <td>{{ item.result_info }}</td>
                                        <td>
                                            <a-tooltip>
                                                <template slot="title">
                                                    {{ item.remark }}
                                                </template>
                                                <a-button type="link" class="ml20">
                                                    查看详情
                                                </a-button>
                                            </a-tooltip>
                                        </td>

                                    </tr>
                                    </tbody>
                                </table>

                                <a-pagination :current="pagejiaozhun" :pageSize="pagesizejiaozhun" @change="onChangejiaozhun" :total="countjiaozhun" v-if="countjiaozhun>pagesizejiaozhun"/>
                            </div>
                        </div>

                    </div>
                    <div style="margin-top: 20px" v-if="tab_active === '6'">
                        <div style="margin-top: 20px">
                            <div v-if="do_type">
                                <a-button @click="showModal2({
                    id:0,
                    name:'',
                    repair_time:'',
                    valid_time:'',
                    result_info:'',
                    maintain_time:'',
                    remark:'',
            })" type="primary">新建维护保养记录
                                </a-button>
                            </div>
                            <div>
                                <table class="layui-table" lay-size="sm">
                                    <thead>
                                    <tr>
                                        <th>名称</th>
                                        <th>维护日期</th>
                                        <th>有效期</th>
                                        <th>下次维护日期</th>
                                        <th>维修结果</th>
                                        <th>备注</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item,key) in weihutableData" :key="key">
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.repair_time }}</td>
                                        <td>{{ item.maintain_time }}</td>
                                        <td>{{ item.valid_time }}</td>
                                        <td>{{ item.result_info }}</td>
                                        <td>
                                            <a-tooltip>
                                                <template slot="title">
                                                    {{ item.remark }}
                                                </template>
                                                <a-button type="link" class="ml20">
                                                    查看详情
                                                </a-button>
                                            </a-tooltip>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                                <a-pagination :current="pagebaoyang" :pageSize="pagesizebaoyang" @change="onChangebaoyang" :total="countbaoyang" v-if="countbaoyang>pagesizebaoyang"/>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 20px" v-if="tab_active === '7'">
                        <div style="margin-top: 20px">

                            <div>
                                <table class="layui-table" lay-size="sm">
                                    <thead>
                                    <tr>
                                        <th>编号</th>
                                        <th>名称</th>
                                        <th>旧位置</th>
                                        <th>旧保管人</th>
                                        <th>新位置</th>
                                        <th>新保管人</th>
                                        <th>备注</th>
                                        <th>登记日期</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="( item,key) in baoguantableData" :key="key">
                                        <td>{{ item.serial_num }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.order_address }}</td>
                                        <td>{{ item.order_name }}</td>
                                        <td>{{ item.news_address }}</td>
                                        <td>{{ item.news_name }}</td>
                                        <td>{{ item.remark }}</td>
                                        <td>{{ item.create_time }}</td>
                                    </tr>
                                    </tbody>
                                </table>

                                <a-pagination :current="pagebiangeng" :pageSize="pagesizebiangeng" @change="onChangebiangeng" :total="countbiangeng" v-if="countbiangeng>pagesizebiangeng"/>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 20px" v-if="tab_active === '8'">
                        <table class="layui-table" lay-size="sm">
                            <thead>
                            <tr>
                                <!--                                <th>-->
                                <!--                                    <a-checkbox></a-checkbox>-->
                                <!--                                </th>-->
                                <th>仪器条件</th>
                                <th>默认值</th>
                                <th>排序</th>
                                <th>操作</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="( item,key) in yiqitableData" :key="key">
                                <!--                                <td>-->
                                <!--                                    <a-checkbox></a-checkbox>-->
                                <!--                                </td>-->
                                <td>
                                    <a-auto-complete v-model="item.ap_name" :data-source="dataSource" class="noborder" style="width: 200px" placeholder="请输入" @select="onSelect1()" @search="onSearch1" @change="onChange1"/>
                                </td>
                                <td>
                                    <a-input v-model="item.ap_value" class="w200 noborder"/>
                                </td>
                                <td>
                                    <a-input v-model="item.sort" class="w200 noborder"/>
                                </td>
                                <td>
                                    <a-button type="primary" @click="Editapparatusparameterclick(item)">
                                        保存
                                    </a-button>
                                    <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="Delapparatusparameterclick(item)">
                                        <template slot="title">
                                            确认删除该项？
                                        </template>
                                        <a-button type="danger" class="ml10">
                                            删除
                                        </a-button>
                                    </a-popconfirm>

                                </td>
                            </tr>
                            <tr>
                                <!--                                <td>-->
                                <!--                                    <a-checkbox></a-checkbox>-->
                                <!--                                </td>-->
                                <td>
                                    <a-auto-complete v-model="creatyiqiform.ap_name" :data-source="dataSource" style="width: 200px; border: 1px solid #d9d9d9; border-radius: 4px" placeholder="请输入" @select="onSelect1" @search="onSearch1" @change="onChange1"/>
                                </td>
                                <td>
                                    <a-input v-model="creatyiqiform.ap_value" class="w200"/>
                                </td>
                                <td>
                                    <a-input v-model="creatyiqiform.sort" class="w200"/>
                                </td>
                                <td>
                                    <a-button type="primary" @click="creatyiqilistclick()">
                                        增加
                                    </a-button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div style="margin-top: 20px" v-if="tab_active === '9'">
                        <table class="layui-table" lay-size="sm">
                            <thead>
                            <tr>
                                <!--                                <th>-->
                                <!--                                    <a-checkbox></a-checkbox>-->
                                <!--                                </th>-->
                                <th>仪器条件</th>
                                <th>默认值</th>
                                <th>排序</th>
                                <th>操作</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="( item,key) in yiqisctableData" :key="key">
                                <!--                                <td>-->
                                <!--                                    <a-checkbox></a-checkbox>-->
                                <!--                                </td>-->
                                <td>
                                    <a-auto-complete v-model="item.ap_name" :data-source="dataSource" style="width: 200px; border: none !important" placeholder="请输入" @select="onSelect1()" @search="onSearch1" @change="onChange1"/>
                                </td>
                                <td>
                                    <a-input v-model="item.ap_value" class="w200 noborder"/>
                                </td>
                                <td>
                                    <a-input v-model="item.sort" class="w200 noborder"/>
                                </td>
                                <td>
                                    <a-button type="primary" @click="Editapparatusparameterclick1(item)">
                                        保存
                                    </a-button>
                                    <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="Delapparatusparameterclick1(item)">
                                        <template slot="title">
                                            确认删除该项？
                                        </template>
                                        <a-button type="danger" class="ml10">
                                            删除
                                        </a-button>
                                    </a-popconfirm>

                                </td>
                            </tr>
                            <tr>
                                <!--                                <td>-->
                                <!--                                    <a-checkbox></a-checkbox>-->
                                <!--                                </td>-->
                                <td>
                                    <a-auto-complete v-model="creatyiqiform1.ap_name" :data-source="dataSource" style="width: 200px; border: 1px solid #d9d9d9; border-radius: 4px" placeholder="请输入" @select="onSelect1(type)" @search="onSearch1" @change="onChange1"/>
                                </td>
                                <td>
                                    <a-input v-model="creatyiqiform1.ap_value" class="w200"/>
                                </td>
                                <td>
                                    <a-input v-model="creatyiqiform1.sort" class="w200"/>
                                </td>
                                <td>
                                    <a-button type="primary" @click="creatyiqilistclick1()">
                                        增加
                                    </a-button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <!--                    <div  style="margin-top: 20px" v-if="tab_active === '10'">-->
                    <!--                     <yiqimoban :info="info"></yiqimoban>-->
                    <!--                    </div>-->


                </a-col>
            </a-row>
        </a-drawer>

        <div v-if="guihuan_info && guihuan_item">
            <Guihuanlist :guihuan_time="guihuan_time" :type="tab_active" :close="modalClose0" :yiqi_info="edit_info" :guihuan_item="guihuan_item" :guihuan_info="guihuan_info" :ok="modalClose0" :cancel="modalClose0" :visible="modal_visible0"></Guihuanlist>
        </div>

        <div v-if="lingyong_info && lingyong_item">
            <Addlingyonglist :waijie_time="lingyong_time" :yiqi_info="edit_info" :close="modalClose00" :lingyong_info="lingyong_info" :lingyong_item="lingyong_item" :ok="modalClose00" :cancel="modalClose00" :visible="modal_visible00"></Addlingyonglist>
        </div>

        <div v-if="waijie_info && waijie_item">
            <Addwaijielist :waijie_time="waijie_time" :yiqi_info="edit_info" :close="modalClose" :waijie_info="waijie_info" :waijie_item="waijie_item" :ok="modalClose" :cancel="modalClose" :visible="modal_visible"></Addwaijielist>
        </div>

        <div v-if="jianding_info && jianding_item">
            <Addjianding :jianding_time="jianding_time" :yiqi_info="edit_info" :close="modalClose1" :jianding_info="jianding_info" :jianding_item="jianding_item" :ok="modalClose1" :cancel="modalClose1" :visible="modal_visible1"></Addjianding>
        </div>

        <div v-if="weihu_info && weihu_item">
            <Addweihulist :weihu_time="weihu_time" :yiqi_info="edit_info" :close="modalClose2" :weihu_info="weihu_info" :weihu_item="weihu_item" :ok="modalClose2" :cancel="modalClose2" :visible="modal_visible2"></Addweihulist>
        </div>

        <div v-if="biangeng_info && biangeng_item">
            <Addbaoguan :biangeng_time="biangeng_time" :yiqi_info="edit_info" :close="modalClose2" :biangeng_info="biangeng_info" :biangeng_item="biangeng_item" :ok="modalClose3" :cancel="modalClose3" :visible="modal_visible3"></Addbaoguan>
        </div>

        <Numberrulelistopen :callback="pushOrder" :ok="modalClose01" :cancel="modalClose01" :Numberrulelist="Numberrulelist"></Numberrulelistopen>

        <div v-if="print_show">
            <printTagdefault @JPrint="JPrint" :info="info" :isDefault="isDefault" :close="onCloseDrawerP" :visible="drawer_visible"></printTagdefault>
        </div>


    </div>
</template>
<script>

    import printTagdefault from './printTag-default/printTag-default.vue';//w选择打印标签
    import Numberrulelistopen from '../../../components/drawer/numberrulelist-open/numberrulelist.vue';//w选择编号规则
    import Addlingyonglist from '../../../components/drawer/addlingyonglist/index.vue';
    import Addwaijielist from '../../../components/drawer/addwaijielist/index.vue';
    import Addjianding from '../../../components/drawer/addjiandingjiaozhun/index.vue';
    import Addweihulist from '../../../components/drawer/addweihulist/index.vue';
    import Addbaoguan from '../../../components/drawer/addbaoguanliat/index.vue';
    import Guihuanlist from '../../../components/drawer/guihuanlist/index.vue';
    import JPrint from "../../../components/print/JPrint";
    import Print from '@/utils/print'


    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        components: {
            Addwaijielist,
            Addjianding,
            Addweihulist,
            Addbaoguan,
            Guihuanlist,
            Addlingyonglist,
            Numberrulelistopen,
            printTagdefault,
            JPrint,

        },
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_info', 'edit_time', 'do_type', 'reloadList', 'num', 'templateList'],
        data() {
            return {
                // 定义控制抽屉显示的变量
                // templateList0:[],//标签模板
                print_img_show: false,
                drawer_visible: false,
                isDefault: '',
                print_show: false,// W选择编号规则弹窗变量
                Numberrulelist: false,// W选择编号规则弹窗变量
                waijie_time: 0,
                waijie_info: false,

                page: 1,
                count: 0,
                pagesize: 0,

                pagewaijie: 1,
                countwaijie: 0,
                pagesizewaijie: 0,

                pagejiaozhun: 1,
                countjiaozhun: 0,
                pagesizejiaozhun: 0,

                pagebaoyang: 1,
                countbaoyang: 0,
                pagesizebaoyang: 0,

                pagebiangeng: 1,
                countbiangeng: 0,
                pagesizebiangeng: 0,

                tab_active: '1',
                tabPosition: 'left',
                labelCol: {span: 7},
                wrapperCol: {span: 17},
                info: {
                    name: '',
                    verify_time: '',
                    effect_time: '',
                    nextver_time: '',
                    purchase_time: '',
                    stop_time: '',
                    enable_time: '',
                    arrive_time: '',
                    danda_custos_ids: [],
                    danda_used_ids: [],
                },
                formInline: {},
                modal_visible00: false,
                modal_visible0: false,
                modal_visible: false,
                modal_visible1: false,
                modal_visible2: false,
                modal_visible3: false,
                types: [],
                manufacturer: [],
                custos_ids: [],
                storage_addresser: [],
                used_ids: [],
                lingyongtableData: [],
                waijietableData: [],
                weihutableData: [],
                baoguantableData: [],
                jiaozhuntableData: [],
                guihuan_info: false,
                default_tag: false,
                guihuan_item: false,
                waijie_item: false,
                guihuan_time: 0,
                weihu_info: false,
                weihu_item: false,
                weihu_time: 0,
                lingyong_info: false,
                lingyong_item: false,
                lingyong_time: 0,
                jianding_info: false,
                jianding_item: false,
                jianding_time: 0,
                biangeng_info: false,
                biangeng_item: false,
                biangeng_time: 0,
                list_default_tag: [],
                value: '',
                dataSource: [],
                yiqiform: {
                    ap_name: '',
                    ap_value: '',
                    sort: '',
                },
                creatyiqiform: {
                    ap_name: '',
                    ap_value: '',
                    sort: '',
                },
                creatyiqiform1: {
                    ap_name: '',
                    ap_value: '',
                    sort: '',
                },
                yiqitableData: [],
                yiqisctableData: [],
                yangpinguize_list: []
            }
        },
        watch: {
            tab_active() {
                this.dataSource = []
                this.onSearch1('')
            },
            edit_time() {
                this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
                this.info = JSON.parse(JSON.stringify(this.edit_info))
                this.info.trace_type = Number(this.info.trace_type)
                if (this.info.trace_type == 0) {
                    this.info.trace_type = undefined
                }
                this.info.used_status = Number(this.info.used_status)
                if (this.info.used_status == 0) {
                    this.info.used_status = undefined
                }
                this.info.danda_custos_ids = ((this.info.danda_custos_ids).toString()).split(',').map((item) => {
                    return Number(item)
                })
                this.info.danda_used_ids = ((this.info.danda_used_ids).toString()).split(',').map((item) => {
                    return Number(item)
                })


                this.Sampletypelist() //获取基础字段全部列表
                this.Sampletypelist1() //获取基础字段全部列表
                this.Manufacturerlist() //获取生产厂家所有列表
                this.Dandaalllist() //项目负责人所有列表
                this.Dandaalllist1() //项目负责人所有列表
                this.Get_apparatusinfo() //领用记录
                this.Get_apparatusinfowaijie() //外借记录
                this.Get_apparatusinfoweihu() //维护记录
                this.Get_apparatusinfobaoguan() //保管记录
                this.Get_apparatusinfojiaozhun() //检定校准记录
                this.Get_apparatus_parameter() //仪器参数列表
                this.Get_apparatus_parameter1() //仪器参数列表
            },
        },
        mounted() {
            this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
            this.info = JSON.parse(JSON.stringify(this.edit_info))
            this.info.used_status = Number(this.info.used_status)
            // console.log('his.info.trace_typehis.info.trace_typehis.info.trace_typehis.info.trace_type', this.info.danda_custos_ids)
            this.info.trace_type = Number(this.info.trace_type)
            if (this.info.trace_type == 0) {
                this.info.trace_type = undefined
            }
            if (this.info.used_status == 0) {
                this.info.used_status = undefined
            }

            this.info.danda_custos_ids = ((this.info.danda_custos_ids).toString()).split(',').map((item) => {
                return Number(item)
            })


            this.info.danda_used_ids = ((this.info.danda_used_ids).toString()).split(',').map((item) => {
                return Number(item)
            })

            this.Sampletypelist() //获取基础字段全部列表
            this.Sampletypelist1() //获取基础字段全部列表
            this.Manufacturerlist() //获取生产厂家所有列表
            this.Dandaalllist() //项目负责人所有列表
            this.Dandaalllist1() //项目负责人所有列表
            this.Get_apparatusinfo() //领用记录
            this.Get_apparatusinfowaijie() //外借记录
            this.Get_apparatusinfoweihu() //维护记录
            this.Get_apparatusinfobaoguan() //保管记录
            this.Get_apparatusinfojiaozhun() //检定校准记录
            this.Get_apparatus_parameter() //仪器参数列表
            this.Get_apparatus_parameter1() //仪器参数列表
            this.yangpinguizeliebiao()
            // this.Get_default_tag()//默认标签打印
            // this.taglist(3)
        },
        methods: {
            yangpinguizeliebiao() {
                this.$sa0.post({
                    url: this.$api('样品管理：获取编号列表'),
                    data: {
                        num_type: 3
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.yangpinguize_list = response.data.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            getyangpinbianhao(id) {
                this.$sa0.post({
                    url: this.$api('样品管理：获取样品编号'),
                    data: {
                        id, num: 1
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.info.serial_num = response.data.serialnumber[0]
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            getyangpinbianhaomoren() {
                // 样品管理：获取样品编号
                this.$sa0.post({
                    url: this.$api('样品管理：获取样品默认编号'),
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.getyangpinbianhao(response.data.info.id)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            Delapparatusparameterclick1(item) {
                this.$sa0.post({
                    url: this.$api('Del_apparatus_parameter'),
                    data: {
                        apparatus_parameter_id: item.id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_apparatus_parameter1()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            Editapparatusparameterclick1(item) {
                this.$sa0.post({
                    url: this.$api('Edit_apparatus_parameter'),
                    data: {
                        ap_name: item.ap_name,
                        ap_value: item.ap_value,
                        sort: item.sort,
                        apparatus_parameter_id: item.id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_apparatus_parameter1()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            creatyiqilistclick1() {
                if (this.creatyiqiform1.sort === '') {
                    layer.msg('排序不能为空')
                } else {
                    this.$sa0.post({
                        url: this.$api('Create_apparatus_parameter'),
                        data: {
                            ap_name: this.creatyiqiform1.ap_name,
                            ap_value: this.creatyiqiform1.ap_value,
                            sort: this.creatyiqiform1.sort,
                            apparatus_id: this.edit_info.id,
                            type: 2,
                        },
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                layer.msg(response.message)
                                this.Get_apparatus_parameter1()
                                this.creatyiqiform1 = {
                                    ap_name: '',
                                    ap_value: '',
                                    sort: '',
                                }
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })
                }
            },

            // 仪器参数列表
            Get_apparatus_parameter1() {
                this.$sa0.post({
                    url: this.$api('Get_apparatus_parameter'),
                    data: {
                        apparatus_id: this.edit_info.id,
                        type: 2,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.yiqisctableData = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            Delapparatusparameterclick(item) {
                this.$sa0.post({
                    url: this.$api('Del_apparatus_parameter'),
                    data: {
                        apparatus_parameter_id: item.id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_apparatus_parameter()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })

            },


            Editapparatusparameterclick(item) {
                this.$sa0.post({
                    url: this.$api('Edit_apparatus_parameter'),
                    data: {
                        ap_name: item.ap_name,
                        ap_value: item.ap_value,
                        sort: item.sort,
                        apparatus_parameter_id: item.id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_apparatus_parameter()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            creatyiqilistclick() {

                if (this.creatyiqiform.sort === '') {
                    layer.msg('排序不能为空')
                } else {
                    this.$sa0.post({
                        url: this.$api('Create_apparatus_parameter'),
                        data: {
                            ap_name: this.creatyiqiform.ap_name,
                            ap_value: this.creatyiqiform.ap_value,
                            sort: this.creatyiqiform.sort,
                            apparatus_id: this.edit_info.id,
                            type: 1,
                        },
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                layer.msg(response.message)
                                this.Get_apparatus_parameter()
                                this.creatyiqiform = {
                                    ap_name: '',
                                    ap_value: '',
                                    sort: '',
                                }
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })
                }
            },


            // 仪器参数列表
            Get_apparatus_parameter() {
                this.$sa0.post({
                    url: this.$api('Get_apparatus_parameter'),
                    data: {
                        apparatus_id: this.edit_info.id,
                        type: 1,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.yiqitableData = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            onSearch1(searchText) {
                this.$sa0.post({
                    url: this.$api('Get_apparatus_parameter'),
                    data: {
                        apparatus_id: this.edit_info.id,
                        type: Number(this.tab_active) === 8 ? 1 : 2,
                        ap_name: searchText
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.dataSource = response.data.list.map(item => item.ap_name)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            onSelect1(value) {
                console.log('onSelect1', value);
            },
            onChange1(value) {
                console.log('onChange1', value);
            },


            JPrint(src_list) {
                this.$emit('PrintTAG', src_list)
            },
            doPrint() {
                if (this.info.serial_num.length === 0) {
                    layer.msg('请选择编号')
                } else if (this.info.name.length === 0) {
                    layer.msg('请输入名称')
                } else {
                    let template = this.templateList.filter(item => item.is_default === 1)
                    let template_info = JSON.parse(JSON.stringify(template[0]))
                    template_info.demo_num = this.info.serial_num
                    template_info.demo_text = this.info.name
                    let src_list = Print.getImg(template_info)
                    this.$emit('PrintTAG', [src_list])
                }
            },

            taglist(id) {
                this.$sa0.post({
                    url: this.$api('Get_tag'),
                    data: {
                        tag_type: id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.templateList0 = response.data.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            PrintTAG(index) {
                if (this.info.serial_num.length === 0) {
                    layer.msg('请选择编号')
                } else if (this.info.name.length === 0) {
                    layer.msg('请输入名称')
                } else {
                    let template = this.templateList.filter((item, key) => {
                        if (key === index) {
                            return true
                        }
                    })
                    let template_info = template[0]
                    template_info.demo_num = this.info.serial_num
                    template_info.demo_text = this.info.name
                    let src_list = Print.getImg(template_info)
                    this.$emit('PrintTAG', [src_list])
                }
            },


            //获取默认标签
            Get_default_tag() {
                console.log({
                    tag_type: 2,
                    name: this.info.name,
                    serial_num: this.info.serial_num
                })
                this.$sa0.post({
                    url: this.$api('Get_default_tag'),
                    data: {
                        tag_type: 2,
                        name: this.info.name,
                        serial_num: this.info.serial_num
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            console.log('response.data.tag', JSON.stringify(response.data.tag))
                            let data = JSON.parse(JSON.stringify(response.data.tag));
                            let name = this.info.name
                            let serial_num = this.info.serial_num
                            data.demo_num = serial_num
                            data.demo_text = name
                            response.data.tag.qrcode_url = this.getImg(data)
                            this.list_default_tag = response.data.tag;
                            console.log(this.list_default_tag)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            // 定义 关闭对话框时的 函数 打印标签
            onCloseDrawerP() {
                this.drawer_visible = false;
                this.print_show = false
            },
            // 定义 打开对话框时的 函数 打印标签
            showDrawerP(info, isDefault) {
                if (info.name === '') return layer.msg('请先输入名称')
                if (info.serial_num === '') return layer.msg('请先输入编号')
                this.info = info
                if (isDefault == 0) {
                    this.$sa0.post({
                        url: this.$api('Get_default_tag'),
                        data: {
                            tag_type: 2,
                            name: this.info.name,
                            serial_num: this.info.serial_num
                        },
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                console.log('response.data.tag', JSON.stringify(response.data.tag))
                                let data = JSON.parse(JSON.stringify(response.data.tag));
                                let name = this.info.name
                                let serial_num = this.info.serial_num
                                data.demo_num = serial_num
                                data.demo_text = name
                                response.data.tag.qrcode_url = this.getImg(data)
                                this.default_tag = response.data.tag;
                                this.$sa0.print('#tiaoma', () => {
                                    $('#tiaoma').hide();
                                })
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })
                } else {
                    this.isDefault = isDefault
                    this.drawer_visible = true;
                    this.print_show = true
                }
            },

            pushOrder(order) {
                this.info.serial_num = order
                this.modalClose01();
            },
            // 定义 打开对话框时的 函数 //W编号规则弹窗
            showModal01() {
                this.Numberrulelist = true
            },
            // 定义 关闭对话框时的 函数 //W编号规则弹窗
            modalClose01() {
                this.Numberrulelist = false
            },

            nummoren() {
                this.$sa0.post({
                    url: this.$api('Get_default_serialnumber'),
                    data: {
                        num_type: 3,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.info.serial_num = response.data.serialnumber;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //领用记录列表
            Get_apparatusinfo() {
                console.log('this.apparatus_id---------', this.apparatus_id)
                this.$sa0.post({
                    url: this.$api('Get_apparatusinfo'),
                    data: {
                        type: 1,
                        apparatus_id: this.edit_info.id,
                        type_return: 1,
                        page: this.page
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            console.log('Get_apparatusinfo')

                            // result  return

                            this.lingyongtableData = response.data.return.list;
                            // console.log('t123',this.lingyongtableData)
                            // this.count = response.data.return.count;//分页
                            // this.pagesize = response.data.return.pagesize;//分页
                            this.count = Number(response.data.return.count);
                            this.pagesize = Number(response.data.return.pagesize);
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //外借记录列表
            Get_apparatusinfowaijie() {
                this.$sa0.post({
                    url: this.$api('Get_apparatusinfo'),
                    data: {
                        type: 2,
                        apparatus_id: this.edit_info.id,
                        type_return: 2,
                        page: this.pagewaijie
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.waijietableData = response.data.return.list;
                            // this.countwaijie = response.data.return.count;//分页
                            // this.pagesizewaijie = response.data.return.pagesize;//分页

                            this.countwaijie = Number(response.data.return.count);
                            this.pagesizewaijie = Number(response.data.return.pagesize);

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //检定校准记录
            Get_apparatusinfojiaozhun() {
                this.$sa0.post({
                    url: this.$api('Get_apparatusinfo'),
                    data: {
                        type: 5,
                        apparatus_id: this.edit_info.id,
                        page: this.pagejiaozhun
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.jiaozhuntableData = response.data.return.list;

                            // this.countjiaozhun = response.data.return.count;//分页
                            // this.pagesizejiaozhun = response.data.return.pagesize;//分页

                            this.countjiaozhun = Number(response.data.return.count);
                            this.pagesizejiaozhun = Number(response.data.return.pagesize);
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            //维护记录列表
            Get_apparatusinfoweihu() {
                this.$sa0.post({
                    url: this.$api('Get_apparatusinfo'),
                    data: {
                        type: 3,
                        apparatus_id: this.edit_info.id,
                        page: this.pagebaoyang
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.weihutableData = response.data.return.list;

                            // this.countbaoyang = response.data.return.count;//分页
                            // this.pagesizebaoyang= response.data.return.pagesize;//分页


                            this.countbaoyang = Number(response.data.return.count);
                            this.pagesizebaoyang = Number(response.data.return.pagesize);
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            //保管记录列表
            Get_apparatusinfobaoguan() {
                this.$sa0.post({
                    url: this.$api('Get_apparatusinfo'),
                    data: {
                        type: 4,
                        apparatus_id: this.edit_info.id,
                        page: this.pagebiangeng
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.baoguantableData = response.data.return.list;

                            // this.countbiangeng = response.data.return.count;//分页
                            // this.pagesizebiangeng= response.data.return.pagesize;//分页

                            this.countbiangeng = Number(response.data.return.count);
                            this.pagesizebiangeng = Number(response.data.return.pagesize);

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 获取仪器分类
            // Get_apparatus() {
            //     this.$sa0.post({
            //         url: this.$api('Sampletypelist'),
            //         data: {
            //             type: 8
            //         },
            //     }).then((response) => {
            //         this.$sa0.response({
            //             response: response.data,
            //             then: (response) => {
            //                 this.types = response.data.list
            //                 console.log('types', this.types)
            //             },
            //             error: (response) => {
            //                 layer.msg(response.message)
            //             },
            //         })
            //     })
            // },


            // 项目负责人所有列表
            Dandaalllist() {
                this.$sa0.post({
                    url: this.$api('Dandaalllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.custos_ids = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 项目负责人所有列表
            Dandaalllist1() {
                this.$sa0.post({
                    url: this.$api('Dandaalllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.used_ids = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            // 获取仪器分类
            Sampletypelist() {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: 8
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.types = response.data.list
                            console.log('types', this.types)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            // 获取存放地点
            Sampletypelist1() {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: 3
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.storage_addresser = response.data.list

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            // 获取生产厂家所有列表
            Manufacturerlist() {
                this.$sa0.post({
                    url: this.$api('Manufacturerlist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.manufacturer = response.data.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            // 分页
            // onChange(page) {
            //     this.$router.push({
            //         query: {
            //             page: page
            //         }
            //     })
            // },

            onChange(page) {
                this.page = page;
                this.Get_apparatusinfo() //领用记录
            },

            // 分页外借
            onChangewaijie(pagewaijie) {
                this.pagewaijie = pagewaijie;
                this.Get_apparatusinfowaijie() //外借记录
            },

            // 分页校准
            onChangejiaozhun(pagejiaozhun) {
                this.pagejiaozhun = pagejiaozhun;
                this.Get_apparatusinfojiaozhun() //检定校准记录
            },

            // 分页保养
            onChangebaoyang(pagebaoyang) {
                this.pagebaoyang = pagebaoyang;
                this.Get_apparatusinfoweihu() //维护记录
            },

            // 分页变更
            onChangebiangeng(pagebiangeng) {
                this.pagebiangeng = pagebiangeng;
                this.Get_apparatusinfobaoguan() //保管记录
            },

            doClick() {
                // console.log('this.info.caiji_ids--------------', this.info.caiji_ids)
                let data = {
                    id: this.info.id,
                    serial_num: this.info.serial_num,
                    name: this.info.name,
                    type: this.info.type,
                    model: this.info.model,
                    spec: this.info.spec,
                    level: this.info.level,
                    precision: this.info.precision,
                    supplier_id: this.info.supplier_id,
                    manufacturer: this.info.manufacturer,
                    factory_num: this.info.factory_num,
                    trace_type: this.info.trace_type,
                    verify_year: this.info.verify_year,
                    verify_unit: this.info.verify_unit,
                    verify_time: this.info.verify_time,
                    effect_time: this.info.effect_time,
                    nextver_time: this.info.nextver_time,
                    custos_id: this.info.danda_custos_ids[this.info.danda_custos_ids.length - 1],
                    storage_address: this.info.storage_address,
                    used_id: this.info.danda_used_ids[this.info.danda_used_ids.length - 1],
                    purchase_time: this.info.purchase_time,
                    stop_time: this.info.stop_time,
                    enable_time: this.info.enable_time,
                    used_status: this.info.used_status,
                    price: this.info.price,
                    arrive_time: this.info.arrive_time,
                    remark: this.info.remark,
                    is_show_BI: this.info.is_show_BI,
                }
                console.log(data)
                console.log(JSON.stringify(data))
                // return
                this.$sa0.post({
                    url: Number(this.info.id) !== 0 ? this.$api('Edit_apparatus') : this.$api('Create_apparatus'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },


            doClickadd() {
                // console.log('this.info.caiji_ids--------------', this.info.caiji_ids)
                let data = {
                    id: this.info.id,
                    serial_num: this.info.serial_num,
                    name: this.info.name,
                    type: this.info.type,
                    model: this.info.model,
                    spec: this.info.spec,
                    level: this.info.level,
                    precision: this.info.precision,
                    supplier_id: this.info.supplier_id,
                    manufacturer: this.info.manufacturer,
                    factory_num: this.info.factory_num,
                    trace_type: this.info.trace_type,
                    verify_year: this.info.verify_year,
                    verify_unit: this.info.verify_unit,
                    verify_time: this.info.verify_time,
                    effect_time: this.info.effect_time,
                    nextver_time: this.info.nextver_time,
                    custos_id: this.info.danda_custos_ids[this.info.danda_custos_ids.length - 1],
                    storage_address: this.info.storage_address,
                    used_id: this.info.danda_used_ids[this.info.danda_used_ids.length - 1],
                    purchase_time: this.info.purchase_time,
                    stop_time: this.info.stop_time,
                    enable_time: this.info.enable_time,
                    used_status: this.info.used_status,
                    price: this.info.price,
                    arrive_time: this.info.arrive_time,
                    remark: this.info.remark,
                    is_show_BI: this.info.is_show_BI,
                }
                console.log(data)
                console.log(JSON.stringify(data))
                // return
                this.$sa0.post({
                    url: Number(this.info.id) !== 0 ? this.$api('Edit_apparatus') : this.$api('Create_apparatus'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg('修改成功')
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
            onChangetime(value, dateString, e) {
                console.log('dateString----------------------------', dateString)
                console.log('dateString--------e--------------------', e)
                this.info[`${e}`] = dateString;
            },
            onOk(value) {
                console.log('onOk: ', value);
            },
            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
            callback(val) {
                console.log(val);
            },
            showModal00(item) {
                this.lingyong_time = new Date() / 1;
                this.lingyong_info = this.info
                this.lingyong_item = item
                console.log('---------------------------------------------------', this.edit_info)
                this.modal_visible00 = true
            },
            modalClose00() {
                this.modal_visible00 = false
                this.Get_apparatusinfo() //领用记录
                this.reloadList(this.edit_info.id)
            },
            // 定义 打开对话框时的 函数
            showModal0(item) {
                this.guihuan_time = new Date() / 1;
                this.guihuan_info = this.info
                this.guihuan_item = item
                this.modal_visible0 = true
            },
            modalClose0() {
                this.modal_visible0 = false
                this.Get_apparatusinfowaijie() //外借记录
                this.Get_apparatusinfo() //领用记录
                this.reloadList(this.edit_info.id)
                console.log('this.Get_apparatusinfo() //领用记录')
                this.guihuan_info = false
                this.guihuan_item = false
            },
            // 定义 打开对话框时的 函数
            showModal(item) {
                this.waijie_time = new Date() / 1;
                this.waijie_info = this.info
                this.waijie_item = item
                this.modal_visible = true
            },
            modalClose() {
                this.modal_visible = false
                this.Get_apparatusinfowaijie() //外借记录
                this.reloadList(this.edit_info.id)
                this.Get_apparatusinfo() //领用记录
            },
            modalClose1() {
                this.modal_visible1 = false
                this.Get_apparatusinfojiaozhun() //检定校准记录
                this.reloadList(this.edit_info.id)
            },
            // 定义 打开对话框时的 函数
            showModal1(item) {
                this.jianding_time = new Date() / 1;
                this.jianding_info = this.info
                this.jianding_item = item
                this.modal_visible1 = true
            },
            modalClose2() {
                this.modal_visible2 = false
                this.Get_apparatusinfoweihu() //维护记录
            },
            // 定义 打开对话框时的 函数
            showModal2(item) {
                this.weihu_time = new Date() / 1;
                this.weihu_info = this.info
                this.weihu_item = item
                this.modal_visible2 = true
            },


            modalClose3() {
                this.modal_visible3 = false
            },


            // 定义 打开对话框时的 函数
            showModal3(item) {
                this.biangeng_time = new Date() / 1;
                this.biangeng_info = this.info
                this.biangeng_item = item
                this.modal_visible3 = true
            },
            getImg(item) {
                return Print.getImg(item)
            }
        }
    }
</script>

<style>
    .print-tag{
        position: relative;
        }

    .print-show{
        display: none
        }

    .print-tag:hover .print-show{
        display: block;
        position: absolute;
        top: 36px;
        left: 0;
        width: 260px;
        height: auto;
        border: solid 1px #efefef;
        background: #fff;
        padding: 15px;
        z-index: 999;
        }


    .defaultTag{
        position: relative
        }

    .prinTag{
        display: none
        }

    .defaultTag:hover .prinTag{
        display: block;
        position: absolute;
        left: 0;
        top: 30px;
        width: 360px;
        height: 360px;
        border: solid 1px #efefef;
        background: #fff;
        z-index: 99999;
        }

    .w300{
        width: 300px !important
        }

    .list-left{
        background: #f9f9f9;
        border: 1px solid #eeeeee;
        border-right: none;
        height: 950PX;
        padding: 20px 0 0 10px
        }

    .list-right{
        padding: 0 25px;
        }

    .ant-tabs .ant-tabs-left-bar{
        float: right !important;
        margin-right: -1px;
        margin-bottom: 0;
        border-right: 1px solid #e8e8e8;
        width: 100% !important;
        }

    .ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active{
        margin-right: -1px;
        padding-right: 40px !important;
        font-weight: bold;
        }

    .ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active{
        margin-right: -1px;
        padding-right: 40px !important;
        font-weight: bold;
        }

    .ant-drawer-body{
        padding: 0
        }

    .print_img_wrapper{
        position: fixed;
        top: 50%;
        left: 50%;
        background: #ffffff;
        transform: translate(-50%, -50%);
        z-index: 999;
        }

    .ml10{ margin-left: 10px}

    .w200{ width: 200px}

    .noborder, .ant-select-search__field{ border: none !important}

</style>
