<template>
    <div>
        <a-modal title="归还设备" okText="确定" cancelText="取消" :visible="visible" @ok="handleOk" @cancel="handleCancel">
            <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
<!--                <a-form-model-item label="归还设备ID">-->
<!--                    <a-input v-model="form.apparatus_info_id" class="w300" disabled=""/>-->
<!--                </a-form-model-item>-->
                <a-form-model-item label="归还设备编号">
                    <a-input v-model="form.serial_num" class="w300" disabled=""/>
                </a-form-model-item>
                <a-form-model-item label="归还名称">
                    <a-input v-model="form.return_name" class="w300" disabled=""/>
                </a-form-model-item>
                <a-form-model-item label="归还人">
                    <a-input v-model="form.returnee" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="归还日期">
                    <a-date-picker v-model="form.return_data" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="归还时状态">
                    <a-input v-model="form.return_info" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="备注:">
                    <a-textarea placeholder="" :rows="4"  v-model="form.remark" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
    export default {
        // 接收 显示变量 和 确定 取消 函数
        props: ['visible', 'ok', 'cancel', 'edit_info', 'guihuan_time', 'guihuan_item', 'close', 'type'],
        data() {
            return {
                labelCol: {span: 6},
                wrapperCol: {span: 16},
                modal_visible: false,
                form: {
                    remark: '',
                    returnee: '',
                    return_data: null,
                    return_info: '',
                    return_name: '',
                    return_num: '',
                    return_time: '',
                    apparatus_info_id: ''
                },
            }
        },
        mounted() {
            console.log(this.guihuan_item)
            console.log(JSON.stringify(this.guihuan_item))
            this.form = {
                remark: this.guihuan_item.remark,
                returnee: this.guihuan_item.used_name,
                return_data: this.formatDate(new Date()),
                return_info: '',
                return_name: this.guihuan_item.name,
                apparatus_info_id: this.guihuan_item.id,
                serial_num: this.guihuan_item.serial_num,
            }
        },
        methods: {
            formatDate(now) {
                let year = now.getFullYear(),
                    month = now.getMonth() + 1,
                    date = now.getDate(),
                    hour = now.getHours(),
                    minute = now.getMinutes(),
                    second = now.getSeconds();
                return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;
            },
            handleOk() {
                let data = {
                    remark: this.form.remark,
                    returnee: this.guihuan_item.used_name,
                    return_data: this.form.return_data,
                    return_info: this.form.return_info,
                    return_name: this.form.return_name,
                    apparatus_info_id: this.guihuan_item.id,
                    serial_num: this.guihuan_item.serial_num,
                    type: Number(this.type) - 2
                }
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Create_apparatusreturn'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.close()
                            this.form = {
                                remark: '',
                                returnee: this.guihuan_item.used_name,
                                return_data: this.formatDate(new Date()),
                                return_info: '',
                                return_name: this.guihuan_item.name,
                                apparatus_info_id: this.guihuan_item.id,
                                serial_num: this.guihuan_item.serial_num,
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
                console.log('submit!', this.form);
            },


            onChangetime(value, dateString, e) {
                this.form[`${e}`] = dateString;
            },

            onOk(value) {
                console.log('onOk: ', value);
            },
            // 定义 组件 自身的取消函数
            handleCancel() {
                this.cancel()
            },
            // 定义 关闭对话框时的 函数
            modalClose0() {
                this.modal_visible0 = false
            },
            // 定义 打开对话框时的 函数
            showModal0() {
                this.modal_visible0 = true
            },
            onChange(e) {
                console.log(`checked = ${e.target.checked}`);
            },
        }
    }
</script>

<style scoped>

</style>
